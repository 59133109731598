/* PAGE LAYOUT - FACULTY PROFILE */

body#page-layout-faculty-profile #social-icon-bar {
    display: none;
}
body#page-layout-faculty-profile #main-content-wrapper #main-section-wrapper {
    margin: 0 2.5%;
    width: 95%;
}
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-navigation .container,
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content .container {
    margin: 0 5%;
    width: 90%;
    @include phone-portrait {
        margin: 0 5%;
        width: 90%;
    }
}
/* wrapper - grid - container - adjustments */
body#page-layout-faculty-profile #main-content-wrapper.grid-wrapper-min-max {
    grid-template-columns: repeat(12, [col-start] 1fr) !important;
    grid-template-areas:
        "main-section-wrapper"
        "main-aside-wrapper-navigation"
        "main-aside-wrapper-content";
    grid-rows: repeat(4, [col-start] 1fr);
    grid-gap: 0;
    grid-row-gap: 2rem;
    padding: 4rem 10%;
    @include phone-portrait {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto;
        grid-gap: 0;
        grid-row-gap: 2rem;
        margin: 0;
        padding: 2rem;
        width: 100%;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-section-wrapper {
    grid-column: 1 / span 8;
    grid-row: 1 / span 4 ;
    @include phone-portrait {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-navigation {
    grid-column: 9 / span 4;
    grid-row: 1;
    @include phone-portrait {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content {
    grid-column: 9 / span 4;
    @include phone-portrait {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-section-wrapper .padding-sm {
    @include phone-portrait {
        padding: .5rem;
    }
}
/* card styling */
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .image-wrapper {
    margin-bottom: -.25rem;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .image-wrapper.luc-avatar {
    background: lighten($gray-extra-light, 6%);
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .image-wrapper.luc-avatar img {
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .content-grid-wrapper {
    display: block;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .flex-item.content h4,
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content h4 {
    margin-top: 0;
    margin-bottom: calc(var(--baseline)* 0.5* 1rem);
    font-size: clamp(#{$font-step-1-25}, #{$font-step-1-5} + 3.5vw, #{$font-step-1-5});
    line-height: clamp(#{$line-step-1-25}, #{$line-step-1-5} + 3.5vw, #{$line-step-1-5});
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards h5 {
    padding-bottom: calc(var(--baseline)* 0.75* 1rem);
    margin-top: 0 !important;
    margin-bottom: calc(var(--baseline)* 0.75* 1rem);
    font-family: 'Antenna Condensed Light', sans-serif;
    font-size: clamp(#{$font-step-1-25}, #{$font-step-1-25} + 3.5vw, #{$font-step-1-25});
    text-transform: none;
    border-bottom: solid 1px $black;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards p,
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards ul li,
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content li {
    margin: 0 0 .25rem !important;
    font-size: clamp(#{$font-step-095}, #{$font-step-095} + 3.5vw, #{$font-step-095});
    line-height: clamp(#{$line-step-1-25}, #{$line-step-1-25} + 3.5vw, #{$line-step-1-25}) !important;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards ul {
    margin: 0 0 1rem 1.5rem;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards ul li,
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content li {
    text-indent: -1.35rem;
    a {
        font-weight: 600;
    }
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards #social-icon-bar {
    position: relative;
    left: 0;
    display: inline;
    margin: 0;
    opacity: 1 !important;
    article {
        margin: 1.25rem 0 0 0;
    }
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards #social-icon-bar a {
    display: inline;
    margin: 1rem .125rem 0 !important;
    padding: 0 !important;
    background: none !important;
    border-radius: 0 !important;
    border: 0 none !important;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards #social-icon-bar a i {
    margin: 0;
    font-size: clamp(#{$font-step-1-5}, #{$font-step-1-5} + 3.5vw, #{$font-step-1-5});
    line-height: 0;
    color: $maroon;
    cursor: pointer;
}