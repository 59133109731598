body#pattern-library {
  background-color: $gray-light;
  background-image: url('/images/texture-transparent-750.png');
}

#chapter-top {
  padding: 3em;
  color: $gray-light;
  background: $maroon !important;
  ul {
    list-style: none;
    li {
      margin: 1.5em 0;
      font-family: 'Open Sans', sans-serif;
      font-size: clamp(#{$font-step-1-25}, #{$font-step-1-25} + 3.5vw, #{$font-step-1-25});
      a {
        cursor: pointer;
      }
      span {
        font-weight: 700;
        color: $gold-light;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    li h5 {
      color: $gray-extra-light;
    }
    li a {
      font-size: clamp(#{$font-step-095}, #{$font-step-1} + 3.5vw, #{$font-step-1-125});
      font-weight: 400;
      color: $gray-extra-light;
      text-decoration: none;
      transition: .2s;
      &:hover {
        color: $white;
        background-image: linear-gradient(120deg, $brick 0%, $brick 100%);
      }
    }
    li.divider-bullets {
      letter-spacing: .125rem;
      color: $gold;
    }
  }
}

#panel-top {
  padding: 0;
  margin: 5em 0;
  height: 1.975rem;
  border-bottom: 1px solid $gray-light;
  background: $gray;
  .container {
    padding: 0;
    background: $white;
  }
  h5 {
    margin: 0;
    text-align: center;
  }
}
