/* PADDING */

.padding-top-xlg {
  padding-top: 4rem !important;
  @include phone-portrait {
    padding-top: 2rem !important;
  }
}

.padding-lg, .padding-md, .padding-sm, .padding-xsm {
  background: none;
}
.padding-lg {
  padding: 3rem;
  @include tablet-portrait {
    padding: 2rem;
  }
  @include phone-portrait {
    padding: 1rem;
  }
}

.padding-md {
  padding: 2rem;
  @include tablet-portrait {
    padding: 1rem;
  }
  @include phone-portrait {
    padding: 1rem;
  }
}

.padding-sm {
  padding: 1rem;
}

.padding-xsm {
  padding: .5rem;
}

hr .padding-divider{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* 580 */
/*
@media only screen and (max-width: 580px) {
  .divider-sm { padding: 1em 0; }
  .divider-xsm { padding: .5em 0; }
  .divider-xsm-collapse { padding: 0; }
}
*/
