/* SOCIAL ICON BAR */

#social-icon-bar  {
  position: fixed;
  top: 45%;
  left: 0;
	background: $maroon;
  z-index: 10 !important;
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  z-index: 1;
  @include tablet-portrait {
    display: none;
    top: calc(100% - 100px);
    width: 100%;
  }
}

#social-icon-bar.social-not-fixed {
  display: block;
  margin: auto;
  padding: auto;
  box-sizing: border-box;
  position: relative;
  bottom: -1.095rem;
  padding: 0;
  width: 100%;
  text-align: center;
  article {
    display: block;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: -1px;
    padding: 0;
    width: auto;
    height: 100%;
    text-align: center;
  }
}

/* social icons */

#social-icon-bar a, #social-icon-bar.social-not-fixed a  {
  display: block;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  padding: .75rem 1rem;
  font-size: 20px;
  color: $white;
  border: none;
  border-top: solid 1px $gray-extra-light;
  text-decoration: none;
  transition: all 0.3s ease;
  &:first-of-type {
    border-top: none;
  }
  @include tablet-portrait {
    box-sizing: border-box;
    padding: 10px 0;
    width: 20%;
    border-top: none;
    border-left: solid 1px $gray-extra-light;
    &:first-of-type {
      border-left: none;
    }
  }
}

#social-icon-bar.social-not-fixed a  {
  display: inline-block;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 3rem;
  border-top: none;
  border-left: solid 1px $gray-extra-light;
  &:first-of-type {
    border-left: none;
  }
}

#social-icon-bar a:hover { background-color: darken($maroon,10%); }
